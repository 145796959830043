<template>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 11.2202C11.4933 11.2202 11.04 11.4202 10.6933 11.7335L5.94 8.96683C5.97333 8.8135 6 8.66016 6 8.50016C6 8.34016 5.97333 8.18683 5.94 8.0335L10.64 5.2935C11 5.62683 11.4733 5.8335 12 5.8335C13.1067 5.8335 14 4.94016 14 3.8335C14 2.72683 13.1067 1.8335 12 1.8335C10.8933 1.8335 10 2.72683 10 3.8335C10 3.9935 10.0267 4.14683 10.06 4.30016L5.36 7.04016C5 6.70683 4.52667 6.50016 4 6.50016C2.89333 6.50016 2 7.3935 2 8.50016C2 9.60683 2.89333 10.5002 4 10.5002C4.52667 10.5002 5 10.2935 5.36 9.96016L10.1067 12.7335C10.0733 12.8735 10.0533 13.0202 10.0533 13.1668C10.0533 14.2402 10.9267 15.1135 12 15.1135C13.0733 15.1135 13.9467 14.2402 13.9467 13.1668C13.9467 12.0935 13.0733 11.2202 12 11.2202Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>